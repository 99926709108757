import { format } from 'date-fns'
import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import { Subscribe } from 'unstated'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

import TwitterIcon from 'mdi-material-ui/Twitter'

import Layout from '../components/layout'
import NewsletterForm from '../components/newsletter'
import FeaturesContainer from '../containers/FeaturesContainer'
import { FEATURES } from '../containers/FeaturesContainer'
import withRoot from '../withRoot'

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";

const UpdatesPage = ({ location: { pathname }}) => (
  <StaticQuery
    query={graphql`
      query blogIndex {
        allMdx(sort: { fields: frontmatter___date, order: DESC }) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                title
                date
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={({ allMdx: { edges: posts } }) => (
      <Layout
        title="Updates"
        skipOnboarding
        pathname={pathname}
        appBarContent={
          <IconButton
            component="a"
            href="https://twitter.com/reachfiapp"
            aria-label="@reachfiapp on Twitter"
            title="@reachfiapp on Twitter"
            target="_blank"
          >
            <TwitterIcon />
          </IconButton>
        }
      >
        <NewsletterForm />
        <div>
          <Subscribe to={[FeaturesContainer]}>
            {features =>
              features.feature(FEATURES.updates).on &&
              posts.map(({ node: post }) => (
                <Card key={post.id} css={{ marginBottom: 16 }}>
                  <CardActionArea component={Link} to={post.fields.slug}>
                    <CardContent>
                      <Typography gutterBottom variant="h5">
                        {post.frontmatter.title}
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        {format(legacyParse(post.frontmatter.date), convertTokens('MMM D, YYYY'))}
                      </Typography>
                      <Typography component="p">{post.excerpt}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))
            }
          </Subscribe>
        </div>
      </Layout>
    )}
  />
)

export default withRoot(UpdatesPage)
