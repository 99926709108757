import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const NewsletterForm = () => (
  <div id="mc_embed_signup">
    <form
      action="https://kamranicus.us18.list-manage.com/subscribe/post?u=45a27b0b6fc6f929b90e35338&amp;id=1e793e2b4b"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      noValidate
    >
      <Paper
        id="mc_embed_signup_scroll"
        css={{ padding: 16, marginBottom: 24 }}
      >
        <Typography variant="h6">Get notified about updates</Typography>

        <TextField
          type="email"
          label="Email Address"
          name="EMAIL"
          className="required email"
          id="mce-EMAIL"
          margin="dense"
          variant="filled"
        />

        <Button
          type="submit"
          name="subscribe"
          id="mc-embedded-subscribe"
          className="button"
          variant="contained"
          color="primary"
          css={{ margin: 8 }}
        >
          Subscribe
        </Button>

        <div id="mce-responses">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: 'none' }}
          />
          <div
            className="response"
            id="mce-success-response"
            style={{ display: 'none' }}
          />
        </div>
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_45a27b0b6fc6f929b90e35338_1e793e2b4b"
            tabIndex="-1"
          />
        </div>
      </Paper>
    </form>
  </div>
)

export default NewsletterForm
